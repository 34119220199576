// i18next-extract-mark-ns-start index

import {Button, ButtonLink} from 'components/Button';
import {ContactSalesButton} from 'components/ContactSalesButton';
import {Content} from 'components/Content';
import {List} from 'components/List';
import {HomePartners} from 'components/HomePartners';
import {HomePaymentMethods} from 'components/HomePaymentMethods';
import 'keen-slider/keen-slider.min.css';
import {Section, SectionActions, SectionHeader, SectionImage} from 'components/Section';
import {SEO} from 'components/SEO';
import {SignUpButton} from 'components/SignUpButton';
import {graphql, PageProps} from 'gatsby';
import {Link, Trans, useI18next} from 'gatsby-plugin-react-i18next';
import {links} from 'global.config';
import index1 from 'images/payment_gateway.svg';
import index6 from 'images/payment_provider.svg';
import payment_page_EN from 'images/payment-page-preview.png';
import payment_page_ES from 'images/payment-page-preview_es.png';
import credit_cards from 'images/credit-cards.svg';
import shop from 'images/shop.svg';
import payment from 'images/payment.svg';
import sell from 'images/sell.svg';
import integration from 'images/integration.svg';
import money from 'images/money.svg';
import day from 'images/day.svg';
import sales_updates from 'images/sales-updates.svg';
import security from 'images/security.svg';
import React from 'react';
import styled from 'styled-components';
import {AnchorLink} from 'components/AnchorLink';
import {Clients, defaultClientList} from 'components/Clients';
import CardsContainer from 'components/CardsContainer';
import {Card} from 'components/Card';
import Bold from 'components/Bold';
import {StyledDiv, StyledImg} from 'components/StyledSx';
import {ScreenSizes} from '../../types/responsive';
import {BlogLink} from 'components/links/Blog';
import {PrismicPage} from 'components/links/PrismicPage';
import {LanguageEnum} from '../../locales/types';
import monei_pay_qr_es from 'images/monei_pay_qr_es.png';
import monei_pay_qr_en from 'images/monei_pay_qr_en.png';
import {InternalPageLink} from 'components/links/Pages';
import {GoogleReviews, Place} from 'components/GoogleReviews';
import {RelatedBlogPosts} from 'components/RelatedBlogPosts';
import {BlogCategoryType, BlogPost} from '../../types/BlogTypes';
import {getPostsFromPrismicEdges, mapCategory} from 'utils/blog-utils';

const schema = {
  '@context': 'https://schema.org',
  '@type': 'FinancialService',
  address: {
    '@type': 'PostalAddress',
    addressCountry: 'Spain',
    postalCode: '29004',
    addressLocality: 'Málaga',
    streetAddress: 'Palestina, 1'
  },
  name: 'MONEI',
  description:
    'With MONEI, we set out to redefine our understanding of e-commerce. With child-like simplicity, military grade security and speed of a superhero, we made it the best experience possible for our merchants.',
  url: 'https://monei.com/',
  logo: 'https://assets.monei.com/images/logo-square.png',
  image: 'https://assets.monei.com/images/logo-square.png',
  sameAs: [
    'https://es.linkedin.com/company/monei-digital-payments',
    'https://www.instagram.com/moneipayments/',
    'https://monei.com/blog/'
  ]
};

const FirstSection = styled(Section)`
  position: relative;
  padding-top: 0;

  @media (max-width: 768px) {
    margin-top: 40px;
  }
`;

const FirstSectionHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  h2 {
    margin-bottom: 24px;
  }
`;

export const PaymentCard = styled(Card)`
  padding: 32px 62px;
  flex-basis: 100%;
  margin-top: -60px;

  @media (max-width: 768px) {
    margin-top: 0;
    padding: 40px 32px;
  }
`;

const CardWrapper = styled.div`
  max-width: 230px;
`;

const CardImage = styled.img`
  position: absolute;
`;

const IndexBackground = styled.div`
  position: relative;

  &:before {
    content: '';
    display: block;
    position: absolute;
    left: 50%;
    top: -8.9%;
    height: 1256px;
    width: 1203px;
    background: linear-gradient(353.74deg, #41efd6 -47.57%, #aab4e9 13.85%);
    transform: matrix(2, 0, 0, -1, 1248, 4) rotate(47deg) skew(7deg, 1.6deg);
    @media (max-width: 1024px) {
      top: -11.5%;
      transform: matrix(2, 0, 0, -1, 1185, 25) rotate(44.5deg) skew(7deg, 1.6deg);
    }
    @media (max-width: 768px) {
      content: none;
    }
  }

  &:after {
    display: block;
    content: '';
    position: absolute;
    transform: rotate(34deg) matrix(1, 0, 0, -1, 459, -315);
    left: 50%;
    top: -3%;
    opacity: 1;
    height: 972px;
    width: 1289px;
    background: linear-gradient(353.74deg, #41efd6 -41.57%, #aab4e9 53.85%);
    @media (max-width: 1024px) {
      top: -13%;
      transform: rotate(34deg) matrix(1, 0, 0, -1, 470, -240);
    }
    @media (max-width: 768px) {
      content: none;
    }
  }
`;

const IndexImage = styled.img`
  position: absolute;
  left: 56%;
  top: 20px;
  width: 540px;
  z-index: 9;

  @media (max-width: 1024px) {
    left: 60%;
    width: 45%;
    top: 1%;
  }
`;

const IndexContent = styled.div`
  padding-top: 100px;
  padding-bottom: 0;
  max-width: 550px;
  min-height: 600px;
  @media (max-width: ${ScreenSizes.md}) {
    max-width: 500px;
    min-height: 510px;
  }
  @media (max-width: ${ScreenSizes.sm}) {
    max-width: 100%;
    min-height: 410px;
  }
`;

const ReviewsBackground = styled.div`
  position: relative;

  &:before {
    display: block;
    content: '';
    z-index: -1;
    height: 70%;
    position: absolute;
    transform: skew(0, -9deg);
    left: 0;
    right: 0;
    bottom: 0;
    top: 20%;
    background: linear-gradient(96.34deg, #00e9d5 0%, #aab4e9 100%);
  }
`;

const Index: React.FC<PageProps> = (props: any) => {
  const {navigate, t, language} = useI18next();
  const isSpanish = [LanguageEnum.es, LanguageEnum.ca].includes(language as LanguageEnum);
  const scanToPay = isSpanish ? monei_pay_qr_es : monei_pay_qr_en;
  const paymentPagePreview = language === 'es' ? payment_page_ES : payment_page_EN;

  const {data} = props;
  const place: Place = {
    rating: data.googlePlacesPlace.rating,
    user_ratings_total: data.googlePlacesPlace.user_ratings_total,
    reviews: data.allGooglePlacesReview.nodes
  };

  const categories: BlogCategoryType[] = data.prismic.allCategorys.edges.map(mapCategory) || [];
  const popularPosts: BlogPost[] = getPostsFromPrismicEdges({
    posts: data.popularPosts.allPosts.edges,
    categories
  });

  const clientsList = defaultClientList.slice(0, 7).map((client) => {
    return {
      ...client,
      width: 180 * client.scale
    };
  });

  return (
    <>
      <SEO schema={schema} suggestion={t('Getting started with MONEI')} />
      <IndexBackground>
        <Content>
          <IndexContent>
            <SectionHeader underline tagName="h1">
              <Trans>Grow your business faster with the advanced payment platform</Trans>
            </SectionHeader>
            <Trans parent="p">
              Many of Southern Europe’s most successful brands trust MONEI’s omnichannel payment
              platform to manage and process their payments. Join us today to accept the widest
              range of payment methods from a single platform.
            </Trans>
            <SectionActions>
              <SignUpButton variant="light" style={{marginRight: 15}}>
                <Trans>Open an Account</Trans>
              </SignUpButton>
              <ContactSalesButton />
            </SectionActions>
          </IndexContent>
        </Content>
        <IndexImage
          src={index1}
          alt="MONEI payment gateway"
          title="MONEI payment gateway"
          className="hide-on-mobile"
        />
        <Content>
          <FirstSection>
            <div>
              <Clients clientList={clientsList} />
              <FirstSectionHeader>
                <Trans parent="h2">Give your customers the best payment experience</Trans>
                <Trans parent="p">And boost your conversion rate while you're at it.</Trans>
              </FirstSectionHeader>
            </div>
          </FirstSection>

          <CardsContainer>
            <Card>
              <CardImage src={credit_cards} alt="" style={{top: -14}} />
              <Trans parent="h4">Accept more payment methods</Trans>
              <CardWrapper>
                <Trans parent="p">
                  Reach more people and increase your conversion rate by accepting{' '}
                  <InternalPageLink slug="payment-methods">payment methods</InternalPageLink> your
                  customers prefer.
                </Trans>
              </CardWrapper>
            </Card>
            <Card>
              <CardImage src={shop} alt="" style={{top: -38}} />
              <Trans parent="h4">Customize your checkout page</Trans>
              <CardWrapper>
                <Trans parent="p">
                  Boost trust and build awareness by adding your branding and{' '}
                  <AnchorLink href="https://support.monei.com/hc/categories/360003383258">
                    custom domain
                  </AnchorLink>{' '}
                  to your checkout page.
                </Trans>
              </CardWrapper>
            </Card>
            <Card>
              <CardImage src={payment} alt="" style={{top: -20}} />
              <Trans parent="h4">Boost payment success up to 40%</Trans>
              <CardWrapper>
                <Trans parent="p">
                  Set routing rules to increase successful transactions with{' '}
                  <InternalPageLink slug="features/payments-orchestration">
                    payments orchestration
                  </InternalPageLink>
                  .
                </Trans>
              </CardWrapper>
            </Card>
            <PaymentCard>
              <HomePaymentMethods />
              <Button variant="dark" onClick={() => navigate('/payment-methods/')}>
                <Trans>View All Payment Methods</Trans>
              </Button>
            </PaymentCard>
          </CardsContainer>
        </Content>
      </IndexBackground>
      <Content>
        <Section textAlign="center">
          <div>
            <div style={{marginBottom: 122}}>
              <SectionHeader underline tagName="h3">
                <Trans>Save time and money</Trans>
              </SectionHeader>
              <Trans parent="p" style={{maxWidth: 800, margin: 'auto', marginBottom: 18}}>
                Simplify your digital payments so you can focus on growing your business.
              </Trans>
              <Button variant="light" onClick={() => navigate('/payment-methods')}>
                <Trans>Join MONEI Now</Trans>
              </Button>
            </div>
            <CardsContainer>
              <Card>
                <CardImage src={sell} alt="" style={{top: -60}} />
                <Trans parent="h4">Sell more, spend less</Trans>
                <CardWrapper>
                  <Trans parent="p">
                    As you scale your business, your transaction{' '}
                    <Link to="/pricing/">fees will decrease</Link> in real-time.
                  </Trans>
                </CardWrapper>
              </Card>
              <Card>
                <CardImage src={integration} alt="" style={{top: -60}} />
                <Trans parent="h4">Quick and easy integration</Trans>
                <CardWrapper>
                  <Trans parent="p">
                    Set up your checkout page in minutes on{' '}
                    <PrismicPage slug="payment-method-integrations-ecommerce-platforms">
                      popular e-commerce platforms
                    </PrismicPage>{' '}
                    or your custom-built website with our{' '}
                    <AnchorLink href="https://docs.monei.com/api/#tag/Payments">
                      Payments API
                    </AnchorLink>
                    .
                  </Trans>
                </CardWrapper>
              </Card>
              <Card>
                <CardImage src={money} alt="" style={{top: -28}} />
                <Trans parent="h4" style={{maxWidth: 200}}>
                  Earn recurring revenue with subscriptions
                </Trans>
                <CardWrapper>
                  <Trans parent="p">
                    Save time on billing and improve the customer experience with customizable{' '}
                    <InternalPageLink slug="features/recurring-payments">
                      subscription plans
                    </InternalPageLink>
                    .
                  </Trans>
                </CardWrapper>
              </Card>
              <Card>
                <CardImage src={day} alt="" style={{top: -58}} />
                <Trans parent="h4">Get paid in 1 day</Trans>
                <CardWrapper>
                  <Trans parent="p">
                    Enjoy 24-hour payment settlements so you can quickly reinvest your money to grow
                    your business.
                  </Trans>
                </CardWrapper>
              </Card>
              <Card>
                <CardImage src={sales_updates} alt="" style={{top: -10}} />
                <Trans parent="h4">Get real-time sales updates</Trans>
                <CardWrapper>
                  <Trans parent="p">
                    Review your sales analytics daily, weekly, monthly, or for a custom time frame
                    from your easy-to-use dashboard.
                  </Trans>
                </CardWrapper>
              </Card>
              <Card>
                <CardImage src={security} alt="" style={{top: -14}} />
                <Trans parent="h4">Leave payment security to us</Trans>
                <CardWrapper>
                  <Trans parent="p">
                    <BlogLink slug="what-is-3d-secure-and-its-advantages-for-e-commerce">
                      3D Secure 2.0
                    </BlogLink>
                    , <BlogLink slug="psd2">PSD2</BlogLink>, and{' '}
                    <BlogLink slug="understanding-pci-compliance">PCI DSS</BlogLink> Level 1
                    compliance, we’ve got you and your customers covered.
                  </Trans>
                </CardWrapper>
              </Card>
            </CardsContainer>
          </div>
        </Section>
      </Content>
      <Content>
        <Section sx={{alignItems: 'center', gap: '30px', paddingTop: '50px'}}>
          <StyledDiv
            sx={{
              marginBottom: '0',
              display: {all: 'flex', sm: 'none'},
              justifyContent: 'center',
              alignItems: 'center'
            }}>
            <StyledImg src={paymentPagePreview} alt="Monei pay" title="Monei pay" />
          </StyledDiv>
          <div>
            <SectionHeader underline>
              <Trans>Fast payment gateway integration</Trans>
            </SectionHeader>
            <Trans parent="p">
              Quickly and easily plug-in with all{' '}
              <PrismicPage slug="payment-method-integrations-ecommerce-platforms">
                major e-commerce platforms
              </PrismicPage>{' '}
              or integrate with your custom-built website. For in-store sales, try{' '}
              <Link to="/monei-pay/">MONEI Pay</Link>.
            </Trans>
            <HomePartners />
            <SignUpButton variant="dark">
              <Trans>Open an Acount</Trans>
            </SignUpButton>
          </div>
        </Section>
      </Content>
      <Content sx={{margin: {all: '-50px auto'}}}>
        <Section style={{alignItems: 'center'}}>
          <StyledDiv sx={{marginTop: {xl: '-155px', sm: '0'}}}>
            <SectionHeader underline>
              <Trans>
                Accept payments from your phone with <Bold>MONEI Pay</Bold>
              </Trans>
            </SectionHeader>
            <Trans parent="p">
              Need to quickly and securely accept payments in your retail store,{' '}
              <Link to="/monei-pay/qr-code-payment-restaurant/">restaurant</Link>, or at events?
              Process <Link to="/monei-pay/qr/">QR code</Link> payments from any mobile device using
              a hardware free POS system.
            </Trans>
            <Trans>
              Use the <Link to="/monei-pay/">MONEI Pay</Link> mobile payment app to charge customers
              and view in-person and online sales reports from any device. With real-time payment
              history at your fingertips, you’ll always have a pulse on business growth.
            </Trans>
            <SectionActions>
              <Button
                onClick={() => navigate('/monei-pay/')}
                variant="dark"
                style={{marginRight: 15}}>
                <Trans>Learn More</Trans>
              </Button>
            </SectionActions>
          </StyledDiv>
          <SectionImage
            style={{width: '90%', marginBottom: '100px'}}
            mobileWidth={370}
            src={scanToPay}
            alt="Scan to Pay"
            title="Scan to Pay"
            className="hide-on-mobile"
          />
        </Section>
      </Content>
      <ReviewsBackground>
        <div style={{display: 'flex', justifyContent: 'center', width: '100%'}}>
          <SectionHeader underline sx={{textAlign: 'center'}}>
            <Trans>What MONEI merchants are saying</Trans>
          </SectionHeader>
        </div>
        <GoogleReviews place={place} />
      </ReviewsBackground>
      <Content>
        <Section reverseOnMobile>
          <div>
            <SectionHeader underline>
              <Trans>Developers (You're) Welcome</Trans>
            </SectionHeader>
            <Trans parent="p">
              Use <AnchorLink href="https://docs.monei.com/api/">MONEI’s API</AnchorLink> to easily
              integrate with{' '}
              <PrismicPage slug="payment-method-integrations-ecommerce-platforms">
                popular e-commerce platforms
              </PrismicPage>{' '}
              or custom-built websites. Get set up in minutes with our dev documentation and
              integration guides.
            </Trans>
            <List>
              <Trans parent="li">
                Customize checkout appearance and domain using the{' '}
                <AnchorLink href="https://docs.monei.com/docs/integrations/use-prebuilt-payment-page/">
                  Hosted Payment Page
                </AnchorLink>
              </Trans>
              <Trans parent="li">
                Let customers save their payment information for future purchases with{' '}
                <BlogLink slug="what-is-tokenization-and-its-benefits-for-e-commerce">
                  tokenization
                </BlogLink>
              </Trans>
              <Trans parent="li">
                Integrate with <Link to="/shopify-payment-gateway/">Shopify</Link>,{' '}
                <Link to="/wix-payment-gateway/">Wix</Link>,{' '}
                <Link to="/woocommerce-payment-gateway/">WooCommerce</Link>, and other popular
                e-commerce platforms using our official plugins
              </Trans>
              <Trans parent="li">
                Develop custom integrations with our components, APIs, SDKs, and{' '}
                <AnchorLink href="https://docs.monei.com/docs/graphql-api/">GraphQL</AnchorLink>{' '}
                endpoints
              </Trans>
            </List>
            <SectionActions>
              <ButtonLink
                variant="dark"
                href={links.docs}
                target="_blank"
                rel="noopener noreferrer">
                <Trans>Integrate MONEI</Trans>
              </ButtonLink>
            </SectionActions>
          </div>
          <SectionImage
            src={index6}
            alt="Payment Gateway for Developers"
            title="Payment Gateway for Developers"
            width={392}
            height={358}
          />
        </Section>
      </Content>
      {popularPosts.length > 0 && (
        <>
          <Content>
            <Section sx={{paddingBottom: '0px'}}>
              <div style={{display: 'flex', justifyContent: 'center', width: '100%'}}>
                <SectionHeader underline sx={{textAlign: 'center'}}>
                  <Trans>Check our latest blog posts</Trans>
                </SectionHeader>
              </div>
            </Section>
          </Content>
          <RelatedBlogPosts posts={popularPosts} />
        </>
      )}
    </>
  );
};

export default Index;

export const query = graphql`
  query ($language: String!, $prismicLang: String) {
    locales: allLocale(filter: {ns: {in: ["common", "index"]}, language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    googlePlacesPlace {
      user_ratings_total
      rating
    }
    allGooglePlacesReview(sort: {fields: rating, order: DESC}, filter: {rating: {gt: 2}}) {
      nodes {
        id
        rating
        text
        relative_time_description
        profile_photo_url
        author_name
      }
    }
    prismic: prismic {
      allCategorys(lang: $prismicLang) {
        edges {
          node {
            title
            _meta {
              uid
              lang
            }
            order
          }
        }
      }
    }
    popularPosts: prismic {
      allPosts(
        sortBy: meta_firstPublicationDate_DESC
        lang: $prismicLang
        first: 4
      ) {
        edges {
          node {
            title
            image
            summary
            _meta {
              uid
            }
          }
        }
      }
    }
  }
`;
